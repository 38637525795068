import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";
import Nav from "../components/navigation/Nav";
import { StyledPage } from "../styling/styled.components";

export default function MainPage() {
  return (
    <StyledMainPage>
      <Nav />
      <Outlet />
    </StyledMainPage>
  );
}


const StyledMainPage = styled(StyledPage)`
  background-color: white;
  display: flex;
  overflow: hidden;
`;