import styled from "@emotion/styled";
import { useContext } from "react";
import { formatEventTime } from "../../config/util";
import EventSearchContext from "../../context/EventSearchContext";
import Event from "../../models/events/event";

interface props {
  event: Event;
}

export default function EventListCard({ event }: props) {
  const { setEventInView, eventInView } = useContext(EventSearchContext);
  return (
    <StyledEventListCard onClick={() => setEventInView(event)} selected={event._id === eventInView?._id}>
      <section>
        <h4>{event.name}</h4>
        <p>{event.type}</p>
        <p>
          {formatEventTime(new Date(event.startTime), new Date(event.endTime))}
        </p>
      </section>
      <section>
        <p>{event.location.state}</p>
      </section>
    </StyledEventListCard>
  );
}

const StyledEventListCard = styled.div<{ selected?: boolean }>`
  background: ${props => (props.selected ? "#ecf5fc" : "white")};
  height: fit-content;
  width: 100%;
  border-radius: 4px;
  font-size: 13px;
  text-align: left;
  margin-bottom: 1rem;
  display: flex;
  padding: 1% 1% 3% 3%;
  color: ${props => (props.selected ? "#1b3f6d" : "#494e52")};
  border-left: 3px solid #494e52;
  box-shadow: 0px 1px 3px #eaeaea;
  cursor: pointer;

  section:nth-of-type(1) {
    width: 80%;
  }

  section:nth-of-type(2) {
    display: flex;
    width: 15%;
    justify-content: flex-end;
    align-items: center;
    text-align: right;

    p {
      font-size: 9px;
    }
  }

  h4,
  p {
    margin-bottom: 0.1rem;
    margin-top: 0.5rem;
  }

  h4 {
    margin-bottom: 0.1rem;
  }

  p {
    font-size: 11px;
  }
`;
