import { createContext, Reducer } from "react";
import { MutationAction } from "../config/types";

export type AlertSeverity = "error" | "success" | "info" | "warning";

type Alert = {
  message: string;
  severity: AlertSeverity;
  open: boolean;
};

type AlertContextType = Alert & {
  showAlert: ({
    message,
    severity,
  }: {
    message: string;
    severity: AlertSeverity;
  }) => void;
  hideAlert: () => void;
};

export const AlertContext = createContext<AlertContextType>(null);

export const AlertReducer: Reducer<Alert, MutationAction<Alert>> = (
  state,
  action
) => {
  switch (action.type) {
    case "MUTATE_ALERT_STATE":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
