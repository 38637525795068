import styled from "@emotion/styled";
import { ReactComponent as SvgSpinner } from "../../assets/svgs/round.spinner.svg";

export default function Spinner() {
  return (
    <StyledRoundSpinner>
      <SvgSpinner />
    </StyledRoundSpinner>
  );
}

const StyledRoundSpinner = styled.div`
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  margin: auto;
  height: fit-content;
  width: fit-content;

  > svg {
    height: 100%;
    width: 100%;
  }
`;
