import { Reducer } from "react";

import { Action } from "../../config/types";

export type AuthState = {
  id: string;
  email: string;
  accessToken: string;
  exp: Date;
}

const AuthReducer: Reducer<AuthState, Action<AuthState>> = (state, action) => {
  switch (action.type) {
    case 'STORE_AUTH':
      return action.payload;
    default: return state;
  }
}

export default AuthReducer;