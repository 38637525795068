import styled from "@emotion/styled";
import { CancelOutlined, Check } from "@mui/icons-material";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import apiInstance from "../config/api/api";
import { convertDateToString, getFileUrl } from "../config/util";
import { AlertContext } from "../context/AlertContext";
import AuthContext from "../context/AuthContext";
import Event from "../models/events/event";
import HostCard from "./cards/HostCard";
import Spinner from "./loaders/Spinner";
import DeclineEventModal from "./modal/DeclineEventModal";

interface props {
  event: Event;
  setEventInView: Dispatch<SetStateAction<Event>>;
}

export default function EventView({ event, setEventInView }: props) {
  const [approvalLoading, setApprovalLoading] = useState<boolean>(false);
  const [reasonModalOpen, setReasonModalOpen] = useState<boolean>(false);
  const {
    auth: { id, accessToken },
  } = useContext(AuthContext);
  const { showAlert } = useContext(AlertContext);

  const handleApproval = (eventId: string) => {
    setApprovalLoading(true);
    apiInstance
      .approveEvent(
        {
          id: id,
          accessToken: accessToken,
        },
        eventId
      )
      .then(() => {
        showAlert({
          message: "Event approved",
          severity: "success",
        });
        setEventInView(null);
        setApprovalLoading(false);
      })
      .catch(error => {
        showAlert({
          message: error.message,
          severity: "error",
        });
        setApprovalLoading(false);
      });
  };
  return (
    <StyledEventView>
      {event && (
        <>
          <img alt="event" src={getFileUrl(event.images[0])}></img>
          <h3>{event.name}</h3>
          <HostCard id={event.host} />
          <table>
            <tr>
              <th>Name</th>
              <th>{event.name}</th>
            </tr>
            <tr>
              <th>private</th>
              <th>{event._private.toString()}</th>
            </tr>
            <tr>
              <th>Description</th>
              <th>{event.description}</th>
            </tr>
            <tr>
              <th>Start Time</th>
              <th>{convertDateToString(new Date(event.startTime))}</th>
            </tr>
            <tr>
              <th>End Time</th>
              <th>{convertDateToString(new Date(event.endTime))}</th>
            </tr>
            <tr>
              <th>Address</th>
              <th>{event.location.address}</th>
            </tr>
            <tr>
              <th>Location name</th>
              <th>{event.location.name}</th>
            </tr>
            <tr>
              <th>Max Attendees</th>
              <th>{event.maxAttendees}</th>
            </tr>
          </table>
        </>
      )}
      {event && (
        <StyledApprovalControl>
          {!approvalLoading && !event.approved && (
            <>
              <Check
                className="check"
                onClick={() => handleApproval(event._id)}
              />
              <CancelOutlined
                onClick={() => setReasonModalOpen(true)}
                className="cancel"
              />
            </>
          )}

          {approvalLoading && <Spinner />}
        </StyledApprovalControl>
      )}
      <DeclineEventModal
        event={event}
        open={reasonModalOpen}
        setOpen={setReasonModalOpen}
      />
    </StyledEventView>
  );
}

const StyledEventView = styled.div`
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 5%;
  position: relative;

  tr {
    vertical-align: top;
  }

  img {
    width: 70%;
  }

  table {
    min-height: 28rem;
    max-height: 28rem;
    width: 70%;
    font-size: 0.8rem;
    font-weight: 500;
    text-align: left;

    th:nth-of-type(1) {
      font-weight: 800;
    }
  }
`;

const StyledApprovalControl = styled.div`
  height: 3rem;
  width: 15rem;
  border-radius: 20px;
  position: fixed;
  right: 15%;
  bottom: 20px;
  background-color: white;
  box-shadow: 0px 1px 3px #eaeaea;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  .cancel {
    color: red;
  }

  .check {
    color: green;
  }
`;
