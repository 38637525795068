import styled from "@emotion/styled";
import { useContext, useEffect, useState } from "react";
import { AlertContext } from "../../context/AlertContext";
import AuthContext from "../../context/AuthContext";
import Event from "../../models/events/event";

import EventListCard from "../cards/EventListCard";
import EventListCardSkeleton from "../skeletons/EventListCardSkeleton";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import apiInstance from "../../config/api/api";

interface props {
  eventInView: Event;
}

export default function EventList({ eventInView }: props) {
  const [currentFilter, setCurrentFilter] = useState<
    "ongoing" | "unapproved" | "upcoming"
  >("unapproved");
  const [events, setEvents] = useState<Event[]>(null);
  const { showAlert } = useContext(AlertContext);
  const [skip, setSkip] = useState<number>(0);
  const {
    auth: { accessToken, id },
  } = useContext(AuthContext);

  useEffect(() => {
    setEvents(null);
    switch (currentFilter) {
      case "unapproved": {
        apiInstance
          .getUnapprovedEvents(id, accessToken, skip)
          .then(response => setEvents(response.data))
          .catch(error =>
            showAlert({
              message: error.message,
              severity: "error",
            })
          );
        return;
      }
      case "upcoming": {
        apiInstance
          .eventSearch(
            id,
            {
              startTime: new Date().toISOString(),
              longitude: 3.417183,
              latitude: 6.441946,
              searchRadius: "WIDE",
              skip: skip,
            },
            accessToken
          )
          .then(response => setEvents(response.data))
          .catch(error =>
            showAlert({
              message: error.message,
              severity: "error",
            })
          );
        return;
      }
      default: {
        apiInstance
          .getUnapprovedEvents(id, accessToken, skip)
          .then(response => setEvents(response.data))
          .catch(error =>
            showAlert({
              message: error.message,
              severity: "error",
            })
          );
        return;
      }
    }
  }, [currentFilter, skip, accessToken, id, showAlert]);
  return (
    <StyledEventList>
      <Filters>
        <Filter
          onClick={() => setCurrentFilter("unapproved")}
          active={currentFilter === "unapproved"}
        >
          Unapproved
        </Filter>
        <Filter
          onClick={() => setCurrentFilter("ongoing")}
          active={currentFilter === "ongoing"}
        >
          Ongoing
        </Filter>
        <Filter
          onClick={() => setCurrentFilter("upcoming")}
          active={currentFilter === "upcoming"}
        >
          Upcoming
        </Filter>
      </Filters>
      <ul>
        {!events && (
          <>
            <li>
              <EventListCardSkeleton />
            </li>
            <li>
              <EventListCardSkeleton />
            </li>
            <li>
              <EventListCardSkeleton />
            </li>
          </>
        )}
        {events &&
          events.map((event, index) => (
            <EventListCard key={index} event={event} />
          ))}
      </ul>
      <StyledPaginationControls>
        <ArrowBackIos
          onClick={() => setSkip(state => (state === 0 ? state : state - 10))}
        />
        {events && (
          <span>{`${skip + 1} - ${
            events.length === 10 ? `${skip + 10}` : `${events.length}`
          }`}</span>
        )}
        <ArrowForwardIos
          onClick={() => {
            if (events.length === 10) {
              setSkip(state => state + 10);
            }
          }}
        />
      </StyledPaginationControls>
    </StyledEventList>
  );
}

const StyledEventList = styled.div`
  height: 27rem;
  width: 90%;
  position: relative;

  ul {
    list-style: none;
    width: 105%;
    height: 58vh;
    padding-left: 0%;
    padding-right: 5%;
    overflow: auto;

    ::-webkit-scrollbar {
      width: 2px;
    }

    ::-webkit-scrollbar-track {
      background: #b7b7b7;
    }

    ::-webkit-scrollbar-thumb {
      background: #1b3f6d;
      border-radius: 8px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  li {
    width: 100%;
    margin-bottom: 0rem;
  }
`;

const Filters = styled.div`
  height: fit-content;
  padding-bottom: 1rem;
  width: 100%;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
`;

const Filter = styled.div<{ active?: boolean }>`
  min-width: 20%;
  width: fit-content;
  padding-left: 2%;
  padding-right: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => (props.active ? "#112347" : "#b6b7b9")};
  height: 2rem;
  border-radius: 30px;
  font-size: 10px;
  font-weight: 900;
  border: 1.5px solid ${props => (props.active ? "#112347" : "#b6b7b9")};
  margin-right: 3%;
  cursor: pointer;
`;

const StyledPaginationControls = styled.div`
  height: 3rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;

  svg {
    height: 35%;
  }

  span {
    font-size: 13px;
    color: gray;
    width: fit-content;
    background-color: #ecf5fc;
    padding-left: 3%;
    padding-right: 3%;
    border-radius: 8px;
  }
`;
