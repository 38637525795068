import { EventSearchPayload } from "../../models/events/event.dto";
import { generateAuthHeader } from "../util";
import axiosInstance from "./axios";
import { AdminAuthCredentials } from "./dto";

class Api {
  eventSearch(adminId: string, query: EventSearchPayload, accessToken: string) {
    return axiosInstance.get(`/admin/${adminId}/events/`, {
      params: {
        ...query,
      },
      headers: {
        ...generateAuthHeader(accessToken),
      },
    });
  }

  getUserProfile(id: string) {
    return axiosInstance.get(`users/${id}/profile`);
  }

  getUnapprovedEvents(adminId: string, token: string, skip: number) {
    return axiosInstance.get(`/admin/${adminId}/events/unapproved`, {
      headers: {
        ...generateAuthHeader(token),
      },
      params: {
        skip: skip,
      },
    });
  }

  approveEvent(credentials: AdminAuthCredentials, eventId: string) {
    return axiosInstance.patch(
      `/admin/${credentials.id}/events/${eventId}/approved`,
      {},
      {
        headers: {
          ...generateAuthHeader(credentials.accessToken),
        },
      }
    );
  }

  declineEvent({
    eventId,
    reason,
    credentials,
  }: {
    eventId: string;
    reason: string;
    credentials: AdminAuthCredentials;
  }) {
    const { id, accessToken } = credentials;
    return axiosInstance.patch(
      `/admin/${id}/events/${eventId}/decline`,
      {
        reason,
      },
      {
        headers: {
          ...generateAuthHeader(accessToken),
        },
      }
    );
  }
}

const apiInstance = new Api();

export default apiInstance;
