import styled from "@emotion/styled";
import { ReactNode } from "react";

import Spinner from "../loaders/Spinner";

interface props {
  loading?: boolean;
  type: 'submit' | 'button';
  children: ReactNode;
  disabled?: boolean;
}

export default function Button({ loading, type, children, disabled }: props) {
  return (
    <StyledButton type={type} disabled={disabled || loading}>
      { loading && <Spinner /> }
      {!loading && children}
    </StyledButton>
  )
}

const StyledButton = styled.button`
  position: relative;
  border: none;

  :hover {
    cursor: pointer;
  }

  svg {
    height: 60%;
  }

  :disabled {
    background-color: #bdbdbd;
  }
`;