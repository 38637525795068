import styled from "@emotion/styled";
import { AxiosError } from "axios";
import { FormEvent, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../config/api/axios";
import { validateEmail } from "../../config/util";
import { AlertContext } from "../../context/AlertContext";
import AuthContext from "../../context/AuthContext";
import Button from "../buttons/Button";

export default function Login() {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { dispatch, auth } = useContext(AuthContext);
  const { showAlert } = useContext(AlertContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!Object.keys(auth).some(key => key)) {
      navigate("/h/");
    }
  }, [auth, navigate]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    axiosInstance
      .post("/auth/admin/login", {
        email: email,
        password: password,
      })
      .then(response => {
        setLoading(false);

        dispatch({
          type: "STORE_AUTH",
          payload: {
            ...response.data,
            exp: new Date(new Date().getTime() + 20 * 60 * 1000),
          },
        });
        showAlert({
          message: "Authentication success",
          severity: "success",
        });
        setTimeout(() => {
          navigate("/h/");
        }, 2000);
      })
      .catch((error: AxiosError) => {
        setLoading(false);
        const data = error.response.data as unknown & { message: string };
        showAlert({
          message: data?.message || "An error occured",
          severity: "error",
        });
      });
  };
  return (
    <StyledLogin onSubmit={event => handleSubmit(event)}>
      <h4>Login</h4>
      <input
        name="email"
        onChange={event => setEmail(event.target.value)}
        placeholder="email"
        type="email"
        required
      />
      <input
        name="password"
        type="password"
        onChange={event => setPassword(event.target.value)}
        placeholder="password"
        required
      />
      <span className="error"></span>
      <Button
        loading={loading}
        disabled={loading || !email || !password}
        type="submit"
      >
        Login
      </Button>
    </StyledLogin>
  );
}

const StyledLogin = styled.form`
  height: 21rem;
  width: 35%;
  background-color: #f9f9f9;
  margin: auto;
  box-shadow: 0px 10px 30px rgba(69, 72, 111, 0.05);
  border-radius: 4px;
  padding-top: 2rem;

  button {
    display: block;
    margin: auto;
    height: 3rem;
    width: 70%;
    background-color: #112347;
    color: white;
    border-radius: inherit;
  }

  > h4 {
    margin: auto;
    text-align: center;
    font-weight: 400;
    color: #57584e;
    margin-bottom: 2rem;
  }

  @media only screen and (max-width: 800px) {
    width: 60%;
  }
`;
