import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "../components/ProtectedRoute";

import Auth from "../views/Auth";
import EventSearch from "../views/EventSearch";
import MainPage from "../views/MainPage";

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<Auth />} />
      <Route path="/h" element={<ProtectedRoute element={<MainPage />} />}>
        <Route path="/h/" element={<EventSearch />} />
      </Route>
    </Routes>
  );
}
