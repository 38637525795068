export const validateEmail = (email: string) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email.toLowerCase()
  );
};

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const convertDateToString = (date: Date, month?: boolean): string => {
  if (month) {
    return `${MONTHS[date.getMonth()]} ${date.getDay()}`;
  }
  return `${date.getUTCDate()}, ${MONTHS[date.getMonth()]}, ${date.getFullYear()} @ ${getTime(date)}`;
};

export const getTime = (date: Date): string => {
  return `${date.getHours()}:${padDigit(date.getMinutes().toString())}`;
};

const padDigit = (digit: string) => {
  return digit.padStart(2, "0");
};

export const generateAuthHeader = (accessToken: string) => ({
  Authorization: `Bearer ${accessToken}`,
});

export const verifyTokenValidity = (exp: Date) => {
  if (!exp) {
    return false;
  }
  if (new Date().getTime() >= exp.getTime()) {
    return false;
  }
  return true;
};

export const formatEventTime = (startTime: Date, endTime: Date): string => {
  const startString = `${startTime.getDate()} ${MONTHS[startTime.getMonth()]}`;
  const endString = `${endTime.getDate()} ${MONTHS[endTime.getMonth()]}`;

  return `${startString} - ${endString}`;
};

export const getFileUrl = (file: {
  bucket: string;
  key: string;
  hostUpload: boolean;
}): string => `https://storage.googleapis.com/${file.bucket}/${file.key}`;
