import styled from "@emotion/styled";

export const StyledPage = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: #f0f0f0;
`;

export const StyledNestedView = styled.div`
  height: 100%;
  width: 82%;
`;

export const StyledSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 300px;
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  position: relative;
  height: 5rem;
  background-color: #e2e5e7;
  box-shadow: 0 5px 10px 0 rgba(#000, 0.15);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 1rem;
  overflow: hidden;

  .image-skeleton {
    width: 10%;
    left: -45%;
    position: absolute;
    height: 100%;
    background-image: linear-gradient(to left, #e9e9e9, white, #ececec);
    /* background-image: linear-gradient(to left, #e2e5e7, #e2e5e7, #e2e5e7);
    background-image: -moz-linear-gradient(to left, #e2e5e7, #e2e5e7, #e2e5e7);
    background-image: -webkit-linear-gradient(to left, #e2e5e7, #e2e5e7, #e2e5e7); */
    animation: loading 1s infinite;
  }

  @keyframes loading {
    0% {
      left: -45%;
    }
    100% {
      left: 100%;
    }
  }
`;
