import styled from "@emotion/styled";
import { Event } from "@mui/icons-material";
import { ReactComponent as HooLogo } from "../../assets/svgs/hoo.logo.svg";

export default function Nav() {
  return (
    <StyledNav>
      <HooLogo />
      <div className="nav-option-container">
        <NavOption>
          <Event className="icon" />
          Events
        </NavOption>
      </div>
    </StyledNav>
  );
}

const StyledNav = styled.div`
  height: 100%;
  width: 18%;
  background-color: #f0f0f0;
  padding-top: 3rem;

  .icon {
    font-size: 20px;
    margin-right: 5%;
  }

  > svg {
    height: 3rem;
  }

  > * {
    margin-bottom: 3rem;
  }

  .nav-option-container {
    width: 80%;
    margin-left: auto;
  }
`;

const NavOption = styled.div<{ active?: boolean }>`
  height: 3rem;
  width: 100%;
  background-color: #112347;
  border-radius: 10px 0px 0px 10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
`;
