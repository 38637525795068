import styled from "@emotion/styled";
import { Search } from "@mui/icons-material";

interface props {
  placeholder?: string;
}

export default function Input({ placeholder }: props) {
  return (
    <StyledInput>
      <Search />
      <input placeholder={placeholder || ''} />
    </StyledInput>
  );
}

const StyledInput = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  width: 90%;
  border-radius: 4px;
  color: #cecece;
  padding-left: 4%;
  background-color: #f3f4f6;
  margin-bottom: 1rem;

  :focus-within {
    outline: solid 1.5px #205784;
    box-shadow: 0px 1px 3px #ecf5fc;
    color: #205784;
  }

  input {
    display: inline-flex;
    border: none;
    height: 100%;
    width: 90%;
    outline: none;
    color: black;
  }
`;
