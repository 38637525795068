import { createContext, Dispatch } from "react";
import { Action } from "../config/types";
import { AuthState } from "./reducers/AuthReducer";

const AuthContext = createContext<{
  auth: {
    id: string;
    email: string;
    accessToken: string;
    exp: Date;
  };
  dispatch: Dispatch<Action<AuthState>>;
}>({ auth: null, dispatch: null });

export default AuthContext;
