import { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";

interface props {
  element: JSX.Element;
}

export default function ProtectedRoute({ element }: props) {
  const { auth: { email, accessToken, id } } = useContext(AuthContext);

  if (!email || !accessToken || !id) {
    return <Navigate to="/" replace />;
  }
  return element;
}
