import { Snackbar, Alert as MuiAlert } from "@mui/material";
import { useContext } from "react";
import { AlertContext } from "../context/AlertContext";

export default function Alert() {
  const { open, severity, message, hideAlert } = useContext(AlertContext);
  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      sx={{
        width: "18rem",
        height: "fit-content",
        position: "fixed",
        // top: "10rem",
        // right: "0px",
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      onClose={() => hideAlert()}
    >
      <MuiAlert
        sx={{ width: "100%", height: "100%" }}
        onClose={() => hideAlert()}
        severity={severity}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
}
