import styled from "@emotion/styled";
import { StyledSkeleton } from "../../styling/styled.components";

export default function EventListCardSkeleton() {
  return (
    <StyledEventListCardSkeleton>
      <div></div>
    </StyledEventListCardSkeleton>
  );
}


const StyledEventListCardSkeleton = styled(StyledSkeleton)`
  display: flex;
  flex-direction: column;
  flex-basis: 300px;
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  position: relative;
  height: 5rem;
  background-color: #e2e5e7;
  box-shadow: 0 5px 10px 0 rgba(#000, 0.15);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 1rem;
  overflow: hidden;

  > div {
    width: 10%;
    left: -45%;
    position: absolute;
    height: 100%;
    background-image: linear-gradient(to left, #e1e1e1, #e1e1e1, #e1e1e1);
    /* background-image: linear-gradient(to left, #e2e5e7, #e2e5e7, #e2e5e7);
    background-image: -moz-linear-gradient(to left, #e2e5e7, #e2e5e7, #e2e5e7);
    background-image: -webkit-linear-gradient(to left, #e2e5e7, #e2e5e7, #e2e5e7); */
    animation: loading 1s infinite;
  }

  @keyframes loading {
    0% {
      left: -45%;
    }
    100% {
      left: 100%;
    }
  }
`;