import styled from "@emotion/styled";
import { useState } from "react";

import EventView from "../components/EventView";
import EventSearchForm from "../components/forms/EvenSearchForm";
import EventList from "../components/lists/EventList";
import EventSearchContext from "../context/EventSearchContext";
import Event from "../models/events/event";
import { StyledNestedView } from "../styling/styled.components";

export default function EventSearch() {
  const [eventInView, setEventInView] = useState<Event>(null);

  return (
    <EventSearchContext.Provider
      value={{
        eventInView: eventInView,
        setEventInView: setEventInView,
      }}
    >
      <StyledEventSearch>
        <div>
          <h2>Event Search</h2>
          <EventSearchForm />
          <EventList eventInView={eventInView} />
        </div>
        <div>
          <EventView event={eventInView} setEventInView={setEventInView} />
        </div>
      </StyledEventSearch>
    </EventSearchContext.Provider>
  );
}

const StyledEventSearch = styled(StyledNestedView)`
  background-color: white;
  display: flex;

  > div {
    height: 100%;
    padding-left: 3%;
    padding-top: 2.5rem;
  }

  > div:nth-of-type(1) {
    width: 35%;
    border-right: 2px solid #f0efef;

    h2 {
      text-align: left;
      margin-bottom: 2.8rem;
    }
  }

  > div:nth-of-type(2) {
    width: 65%;
    overflow-y: auto;
    padding-bottom: 3rem;
  }
`;
