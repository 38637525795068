import {
  ChangeEvent,
  Dispatch,
  FormEvent,
  FormEventHandler,
  SetStateAction,
  useContext,
  useState,
} from "react";
import MuiModal from "@mui/material/Modal";
import Button from "../buttons/Button";
import Event from "../../models/events/event";
import apiInstance from "../../config/api/api";
import AuthContext from "../../context/AuthContext";
import { AlertContext } from "../../context/AlertContext";
import styled from "@emotion/styled";

interface props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  event: Event;
}

export default function DeclineEventModal({ open, setOpen, event }: props) {
  const handleClose = () => setOpen(false);
  const [reason, setReason] = useState<string>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { showAlert } = useContext(AlertContext);
  const {
    auth: { id, accessToken },
  } = useContext(AuthContext);
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) =>
    setReason(event.target.value);
  const handleDecline = () => {
    setLoading(true);
    apiInstance
      .declineEvent({
        eventId: event._id,
        reason: reason,
        credentials: {
          id: id,
          accessToken: accessToken,
        },
      })
      .then(() => {
        setLoading(false);
        showAlert({
          message: "Event declined",
          severity: "success",
        });
      })
      .catch(error => {
        setLoading(false);
        showAlert({
          message: error.message,
          severity: "error",
        });
      });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleDecline();
  }


  return (
    <MuiModal open={open} onClose={handleClose}>
      <Form onSubmit={handleSubmit}>
        <h4>Decline {event?.name}</h4>
        <label htmlFor="reason" />
        <textarea
          onChange={handleChange}
          required
          name="reason"
          placeholder="reason"
        />
        <Button loading={loading} disabled={!reason} type="submit">
          Submit
        </Button>
      </Form>
    </MuiModal>
  );
}

const Form = styled.form`
  background-color: white;
  width: 40%;
  height: 20rem;
  margin: auto;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding-top: 2.5rem;

  textarea {
    display: block;
    margin: auto;
    width: 60%;
    height: 4.5rem;
    margin-bottom: 2rem;
  }

  h4 {
    text-align: center;
  }

  button {
    width: 60%;
    height: 3rem;
    display: block;
    margin: auto;
    background-color: #112347;
    color: white;
  }
`;
