import styled from "@emotion/styled";
import { useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import "./App.css";
import Alert from "./components/Alert";
import { verifyTokenValidity } from "./config/util";
import {
  AlertContext,
  AlertReducer,
  AlertSeverity,
} from "./context/AlertContext";
import AuthContext from "./context/AuthContext";
import AuthReducer from "./context/reducers/AuthReducer";
import Router from "./routing/Router";

function App() {
  const [auth, dispatch] = useReducer(AuthReducer, {
    id: "",
    email: "",
    accessToken: "",
    exp: null,
  });
  const [alert, alertDispatch] = useReducer(AlertReducer, {
    message: "",
    severity: "error",
    open: false,
  });
  const navigate = useNavigate();
  const showAlert = (alert: { message: string; severity: AlertSeverity }) => {
    alertDispatch({
      type: "MUTATE_ALERT_STATE",
      payload: {
        ...alert,
        open: true,
      },
    });
  };
  const hideAlert = () => {
    alertDispatch({
      type: "MUTATE_ALERT_STATE",
      payload: {
        open: false,
      },
    });
  };

  useEffect(() => {
    if (verifyTokenValidity(auth.exp)) {
      return;
    }
    if (Object.values(auth).some(value => !value)) {
      return;
    }
    dispatch({
      type: "STORE_AUTH",
      payload: {
        id: "",
        email: "",
        exp: null,
        accessToken: "",
      },
    });
    showAlert({
      message: "token expired",
      severity: "error",
    });
    navigate("/");
  });

  return (
    <AuthContext.Provider
      value={{
        auth: auth,
        dispatch: dispatch,
      }}
    >
      <AlertContext.Provider
        value={{
          ...alert,
          showAlert: showAlert,
          hideAlert: hideAlert,
        }}
      >
        <StyledApp>
          <Router />
          <Alert />
        </StyledApp>
      </AlertContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;

const StyledApp = styled.div`
  text-align: center;
  align-items: center;
  height: 100vh;
  position: relative;
`;
