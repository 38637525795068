import styled from "@emotion/styled";
import { ArrowDropDown } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { useEffect, useState } from "react";
import apiInstance from "../../config/api/api";
import useRequestState from "../../hooks/useRequestState";
import UserProfile from "../../models/user/profile";

interface props {
  id: string;
}

export default function HostCard({ id }: props) {
  const [open, setOpen] = useState<boolean>(false);
  const { setError, setData, data } = useRequestState<UserProfile>();
  useEffect(() => {
    apiInstance
      .getUserProfile(id)
      .then(response => setData(response.data))
      .catch(error => setError(error));
  }, [id, setData, setError]);
  return (
    <StyledHostCard loaded={!!data} open={open}>
      <header>
        <Avatar src={data?.image} />
        {data && `${data.firstName} ${data.lastName}`}
        <ArrowDropDown
          onClick={() => {
            if (data) {
              setOpen(state => !state);
            }
          }}
        />
      </header>
      <div>{data && data.description}</div>
    </StyledHostCard>
  );
}

const StyledHostCard = styled.div<{ open: boolean; loaded: boolean }>`
  margin-bottom: 2rem;
  width: 45%;
  height: ${props => (props.open ? "15rem" : "3rem")};
  transition: 0.2s;
  box-shadow: ${props => (props.open ? "0px 1px 3px #eaeaea" : "none")};
  padding-left: 3%;
  padding-right: 3%;
  border-radius: 8px;
  font-size: 12px;
  overflow: hidden;

  div {
    text-align: left;
  }

  > header {
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > svg {
      transform: ${props => (props.open ? "rotate(180deg)" : "none")};
      transition: 0.2s;
    }
  }
`;
