import styled from "@emotion/styled";

import Login from "../components/forms/Login";
import { StyledPage } from "../styling/styled.components";
import { ReactComponent as HooLogo } from "../assets/svgs/hoo.logo.svg";

export default function Auth() {
  return (
    <StyledAuth>
      <header>
        <HooLogo />
        <h2>Hoo Socials</h2>
      </header>
      <Login />
    </StyledAuth>
  );
}

const StyledAuth = styled(StyledPage)`
  > header {
    margin: auto;
    height: 5rem;
    width: 40%;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #112347;

    h4 {
      margin-left: 0;
    }

    > svg {
      height: 2rem;
      width: 3.5rem;

      path {
        fill: #112347;
      }
    }
  }
`;
