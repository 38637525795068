import styled from "@emotion/styled";
import Input from "../Input";

export default function EventSearchForm() {
  return (
    <StyledEventSearchForm>
      <Input />
    </StyledEventSearchForm>
  );
}

const StyledEventSearchForm = styled.form`
  input {
    border-radius: 5px;
  }
`;